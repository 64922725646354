import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-book-service-form.css";
import "../stylesheets/rds-bootstrap.css";
import RdsInputText from "./rds-inputtext";
import RdsSelectInput from "./rds-select-dropdown";
import Rdsbtn from "./rds-btn";
import { formatPhoneNumber } from "../utility";
import { formInputValidations, getDateInputISO } from "../utility";

const RdsBookServiceForm = ({
  heading,
  subheading,
  formFields,
  onFormSubmission,
  cta,
  formSubmissionError,
}) => {

  const initializeFields = () => {
    const stateObj = {};
    formFields?.forEach((input) => {
      stateObj[input.name] = "";
    });
    return stateObj;
  };
  // Lazy load initial state
  const [inputs, setInputs] = useState(initializeFields);
  const [fixInputs, setFixInputs] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target;
    const { name, value } = input;
  
    // If the 'serviceType' is changing, reset 'jobType' to an empty value
    if (name === 'serviceType') {
      setInputs({
        ...inputs,
        [name]: value,
        jobType: '',
      });
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  };
  

  const handleSubmitForm = () => {
    // Ensures nothing is focused after submit click
    document.activeElement.blur();
    let errorCount = 0;

    for (const name of Object.keys(inputs)) {
      const input = document.getElementById(name);
      if (input) {
        input.focus();
        input.blur();
        if (input.closest(".rds-input-text")?.classList?.contains("error")) {
          // If there is any error displaying, increment errorCount
          errorCount++;
        }
      }
    }

    if (onFormSubmission && errorCount === 0) {
      const userData = {
        firstName: inputs.firstName || "",
        lastName: inputs.lastName || "",
        emailAddress: inputs.emailAddress || "",
        phoneNumber: formatPhoneNumber(inputs.phoneNumber) || "",
        zipCode: inputs.zipCode || "",
        serviceType: inputs.serviceType || "",
        jobType: inputs.jobType || "",
        scheduledDate:
          new Date(inputs.scheduledDate).toLocaleDateString("en-US") || "",
      };

      const btn = document.getElementById("rds-btn-book-service");
      if (btn) {
        //be sure button exists before handling it
        btn.innerHTML = "<span>PLEASE WAIT...</span>";
        btn.disabled = true;
      }

      setFixInputs(false);
      onFormSubmission(userData);
    } else {
      setFixInputs(true);
    }
  };
  // Initially set dropdowns since igx is not currently passing an "empty" value
  // (always has a value, but need to set state)
  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      serviceType: document.getElementById("serviceType")?.value || "",
      jobType: document.getElementById("jobType")?.value || "",
    }));
  }, []);

  const isMobile = window.screen.width < 768;
  
  const getDateFormatLabel = (labelText) =>
    labelText === "When do you want to schedule your service?*";

  const getDropdownValue = (fieldData) => {
    let dropDownValues = [];
    const { name } = fieldData;
    if (name === "serviceType") {
      dropDownValues = [...fieldData?.serviceTypes];
    } else if (name === "jobType") {
      if (!inputs?.length) {
        //'jobType' remains empty when onload
        dropDownValues = ["", ...fieldData?.jobTypes?.Heating];
      }
      if (inputs?.serviceType?.length) {
        //Two scenarios here.
        //1. when 'serviceType' changes, resetting 'jobType' to empty.
        //2. when 'jobType' changes, proper jobType values populated in the array
        //base on the 'serviceType' input value 
        dropDownValues = ["", ...fieldData?.jobTypes?.[inputs?.serviceType]];
      }
    }
    return dropDownValues;
  };

  const getTextAreaField = (fieldDetails) => (
    <div className="col-md-12 col-xs-12 mt-3 mb-2" key={fieldDetails?.name}>
      <RdsInputText
        type="textarea"
        rows={5}
        width={"100%"}
        containerClass="rds rds-input-text"
        id={fieldDetails?.name}
        text={fieldDetails?.name}
        placeholder={fieldDetails?.label}
        required={fieldDetails?.isRequired}
        errorHelperText={"Please enter in a valid " + fieldDetails?.label}
        name={fieldDetails?.name}
        onChange={handleInputChange}
      />
    </div>
  );

  const getInputField = (fieldDetails) => {
    const isDateFormatLabelActive = getDateFormatLabel(fieldDetails?.name);
    return (
      <div className="col-md-6 col-xs-12 mt-3 mb-2" key={fieldDetails?.name}>
        <RdsInputText
          type={
            fieldDetails?.controlType?.toLowerCase() === "phone"
              ? "tel"
              : fieldDetails?.controlType?.toLowerCase()
          }
          containerClass="rds rds-input-text"
          id={fieldDetails?.name}
          text={fieldDetails?.name}
          value={inputs?.[fieldDetails?.name]}
          dateFormatLabel={isDateFormatLabelActive}
          name={fieldDetails?.name}
          required={fieldDetails?.isRequired}
          placeholder={fieldDetails?.label}
          errorHelperText={"Please enter in a valid " + fieldDetails?.label}
          onChange={handleInputChange}
        />
      </div>
    );
  };

  const getDropDownField = (fieldDetails) => {
    const dropDownValues = getDropdownValue(fieldDetails);
    const currentValueMap = {
      service: inputs?.service,
      jobType: inputs?.jobType,
    };
    return (
      <div className="col-md-6 col-xs-12 mb-2" key={fieldDetails?.name}>
        <div className="mb-3"></div>
        <RdsSelectInput
          type={fieldDetails?.controlType?.toLowerCase()}
          options={dropDownValues}
          value={currentValueMap[fieldDetails?.name]}
          containerClass="rds rds-input-text"
          id={fieldDetails?.name}
          text={fieldDetails?.name}
          name={fieldDetails?.name}
          required={fieldDetails?.isRequired}
          placeholder={fieldDetails?.label}
          errorHelperText={"Please enter in a valid " + fieldDetails?.label}
          maxLength={fieldDetails?.MaxLength}
          defaultValue={dropDownValues && dropDownValues[0]}
          onChange={handleInputChange}
        />
      </div>
    );
  };

  const getDateField = (fieldDetails) => (
    <div className="col-md-6 col-xs-12 mt-3 mb-2" key={fieldDetails?.name}>
      <RdsInputText
        type={fieldDetails?.controlType?.toLowerCase()}
        containerClass="rds rds-input-text"
        id={fieldDetails?.name}
        text={fieldDetails?.name}
        value={inputs?.[fieldDetails?.name]}
        name={fieldDetails?.name}
        required={fieldDetails?.isRequired}
        placeholder={fieldDetails?.label}
        errorHelperText={"Please enter in a valid " + fieldDetails?.label}
        onChange={handleInputChange}
        onKeyDown={(e) => e.preventDefault()}
        min={getDateInputISO(formInputValidations.scheduledDate.min)}
        max={getDateInputISO(formInputValidations.scheduledDate.max)}
      />
    </div>
  );

  const getFormFields = (formFieldData) => {
    const { controlType } = formFieldData;

    if (controlType?.toUpperCase() === "TEXTBOX") {
      return getTextAreaField(formFieldData);
    }
    if (controlType?.toUpperCase() === "DATE") {
      return getDateField(formFieldData);
    } else if (controlType?.toUpperCase() === "DROPDOWNLIST") {
      return getDropDownField(formFieldData);
    }
    return getInputField(formFieldData);
  };

  return (
    <>
      <div className="rds-book-service-form">
        {heading && <h1 className={`rds-heading`}>{heading}</h1>}
        {subheading && (
          <p className={`rds-book-service-form-subHeading mt-4`}>
            {subheading}
          </p>
        )}
        {fixInputs && (
          <div
            className="alert alert-warning d-flex align-items-center mt-3"
            role="alert"
          >
            <i
              className="fa fa-exclamation-triangle me-2"
              aria-hidden="true"
            ></i>
            <div>Please complete all required fields.</div>
          </div>
        )}
        <div className="row">
          {formFields?.map((fieldDetails, index) => (
            <div key={fieldDetails.name || index}>
              {getFormFields(fieldDetails)}
            </div>
          ))}
        </div>
        {formSubmissionError && (
          <div
            className="alert alert-danger d-flex align-items-center mt-3"
            role="alert"
          >
            <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
            <div>
              Submission failed. Please reload the page and try to submit the
              form again.
            </div>
          </div>
        )}
        <div className="rds-book-service-form-button mt-2">
          <Rdsbtn
            id="rds-btn-book-service"
            text={cta?.text}
            buttonClass="rds rds-btn primary"
            disabled={formSubmissionError}
            onClick={handleSubmitForm}
            mobileWidth={isMobile ? 100 : ""}
          />
        </div>
      </div>
    </>
  );
};

RdsBookServiceForm.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      controlType: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFormSubmission: PropTypes.func,
  cta: PropTypes.shape({
    style: PropTypes.string,
    text: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string,
    openInNewTab: PropTypes.bool,
  }),
  formSubmissionError: PropTypes.bool,
};

export default RdsBookServiceForm;
