import React, { createContext, useEffect, useState } from "react";

const HeaderContext = createContext();

const HeaderCtxProvider = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isBCPresent, setIsBCPresent] = useState(false);
  const [isFranchiseRibbonPresent, setIsFranchiseRibbonPresent] =
    useState(false);
  const [footerData, setFooterData] = useState({});
  const [franchiseData, setFranchiseData] = useState({});
  
  useEffect(() => {
    let resizeObserver;
    const intervalId = setInterval(() => {
      const header = document.getElementsByTagName("header")[0];
      const bc = document.getElementById("breadcrumb");
      const franchiseRibbon = document.getElementById("franchise-ribbon");

      if (bc) {
        setIsBCPresent(Boolean(bc));
      } else setIsBCPresent(false);

      if (franchiseRibbon) {
        setIsFranchiseRibbonPresent(Boolean(franchiseRibbon));
      } else setIsFranchiseRibbonPresent(false);

      if (header) {
        const updateHeight = () =>
          setHeaderHeight(header.getBoundingClientRect().height);

        updateHeight(); //initial load setter

        //The ResizeObserver web API will detect the changes in the
        //element (header) content height difference and updating the state.
        resizeObserver = new ResizeObserver(() => {
          updateHeight(); //on resize detection setter
        });
        resizeObserver.observe(header);
      }
    }, 500);

    //Fetch Data from the LocalStorage of the recentvisited franchise
    const loadFranchiseData = () => {
      const data = JSON.parse(localStorage.getItem("franchiseSiteMenuData"));
      if (data) {
        const { franchiseHeader, franchiseFooter } = data;
        setFranchiseData(franchiseHeader);
        setFooterData(franchiseFooter);
      }
    };

    loadFranchiseData(); //for initial load

    //on localstoage update
    window.addEventListener("franchiseLocalStorageUpdate", loadFranchiseData);

    return () => {
      clearInterval(intervalId);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      window.removeEventListener("franchiseLocalStorageUpdate", loadFranchiseData);
    };
  }, [headerHeight, isBCPresent, isFranchiseRibbonPresent]);

  const headerCtxVal = {
    headerHeight,
    isBCPresent,
    isFranchiseRibbonPresent,
    franchiseData,
    footerData,
  };

  return (
    <HeaderContext.Provider value={headerCtxVal}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderCtxProvider };

export default HeaderContext;
