import React from 'react';

import Phone from '../../../../SvgIconComponents/Phone';
import { formatPhoneNumber } from '../../../../../utility';
import { SCHEDULE_NOW } from '../../../../../constant';
import { dataPush } from "../../../../../GAnalytics";
import { PHONE_NUMBER_CLICK } from "../../../../../gaTrack";
import styles from "./phonepanel.module.css"

const PhonePanel = (props) => {
    const {
        franchiseName,
        franchiseAddress,
        franchisePhoneNumber
    } = props;

    const handleWindowTracker = (e) => {
        e.stopPropagation();
        const location = window.location.href;
        // Google Analytics event tracking code
        dataPush(
            PHONE_NUMBER_CLICK,
            franchiseName,
            franchiseAddress,
            location
        );
    };

    return (
        <>
            <div className={`${styles.label} text-center`}>{SCHEDULE_NOW?.endsWith(':') ? SCHEDULE_NOW : `${SCHEDULE_NOW}:`}</div>
            <div className={`${styles.phoneGroup}`}>
                <Phone fillColor={"#d5202e"} width={"30"} height={"30"} />
                <a
                    href={`tel:${franchisePhoneNumber}`}
                    className={`${styles.displayStyles} text-decoration-none`}
                    onClick={(e) => handleWindowTracker(e)}
                >
                    {formatPhoneNumber(franchisePhoneNumber)}
                </a>
            </div>
        </>
    )
}

export default React.memo(PhonePanel)
