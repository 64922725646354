import React from "react";
import "../stylesheets/rds-body-copy-tray.css";
import Rdsbtn from "./rds-btn";
import { getTrayStyle, getTextStyle } from "../utility";
import { Markup } from "interweave";

class RdsBodyCopyTray extends React.Component {
  render() {
    const {
      backgroundColor,
      reverse,
      tabId,
      sectionHeading,
      heading,
      subHeading,
      summary,
      linkElement,
      cta,
      anchorTagValue,
      franchiseData,
      headingAlignment,
    } = this.props;

    const trayStyle = getTrayStyle(backgroundColor);
    const containerClasses = `rds-bodycopy-tray-container-new ${
      reverse ? "rds-bodycopy-tray-flex-row-reverse" : ""
    }`;
    const columnTopClasses = `rds-bodycopy-tray-coloumn-top
      }`;

    let textStyle = getTextStyle(backgroundColor);
    const headerFranchisePhone =
      franchiseData?.franchiseDetails?.headerFranchisePhone;
    const renderRdsBtn = !headerFranchisePhone ? null : (
      <Rdsbtn
        tel={headerFranchisePhone}
        text={`CALL US TO LEARN MORE`}
        buttonClass="rds rds-btn primary phone-icon white-phone"
        franchiseDetails={franchiseData?.franchiseDetails}
      />
    );

    const textAlignment =
      headingAlignment == "Left"
        ? "text-start"
        : headingAlignment == "Right"
        ? "text-end"
        : "text-center";

    return (
      <>
        <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
          <div className="container">
            <div className="rds-bodycopy-tray">
              <div id={tabId} className={containerClasses}>
                <div className={`rds-bodycopy-tray-row`}>
                  <div className={`d-flex flex-column gap-2 ${columnTopClasses}`}>
                    {sectionHeading && <h2
                      className={`rds-section-heading ${textAlignment} my-0`}
                      style={textStyle}
                    >
                      {sectionHeading}
                    </h2>}
                    {heading && (
                      <h3
                        className={`rds-heading ${textAlignment} my-0`}
                        style={textStyle}
                      >
                        {heading}
                      </h3>
                    )}
                    {subHeading && (
                      <h4 className="rds-font-title-5 my-0" style={textStyle}>
                        {subHeading}
                      </h4>
                    )}
                    {summary && (
                      <p
                        className="rds-bodycopy-tray-description rds-description my-0"
                        style={textStyle}
                      >
                        <Markup content={summary} />
                      </p>
                    )}
                    {linkElement && <div>{linkElement}</div>}
                    {cta && cta.style === "Phone_Number" && <div>{renderRdsBtn}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RdsBodyCopyTray;
