import React from "react";
import "../stylesheets/rds-external-system-message.css";


const ExternalSystemMessageTray = (data) => {
  const externalFranchiseMessage = data?.externalFranchiseMessage?.message;

  return (
    <div className="rds-external-system-message-wrapper">
      <span>{externalFranchiseMessage}
        {data?.externalFranchiseMessage?.blogRedirectDetails &&
          <a
            href={data?.externalFranchiseMessage?.blogRedirectDetails?.redirectUrl}
            rel="noreferrer"
            target={"_self"}
          >
            {data?.externalFranchiseMessage?.blogRedirectDetails?.redirectText}
          </a>
        }
      </span>

    </div>
  );
};

export default ExternalSystemMessageTray;
