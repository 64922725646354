import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import MenuItems from "../MenuItems";
import styles from "./dropdown.module.css";

const DropDown = (
  {
    subMenuItems = [],
    dropdown = false,
    depthLevel = 0,
    position = {},
    parentMenuName = "",
    secNav,
    ...rest
  }
) => {
  const {
    leftPos,
    topPos,
    height,
    parentTop
  } = position;
  depthLevel = depthLevel + 1;

  const [secMenuTopPos, setSecMenuTopPos] = useState("");
  const [borderRds, setBorderRds] = useState({});

  useEffect(() => {
    const leftPosVal = parseInt(leftPos, 10);

    if (depthLevel === 2) {
      const brStyles = {
        borderBottomRightRadius: leftPosVal < 0 ? 0 : "",
        borderBottomLeftRadius: leftPosVal > 0 ? 0 : "",
      }
      setBorderRds(brStyles)
    }

  }, [leftPos, depthLevel]);

  //To calculate the height of the secondary NavBar and assigning that
  //value as the top position of the dropdown menu.
  //On window resize also this function called.
  useEffect(() => {
    const calcSecMenuTop = () => {
      const secNavBar = document.getElementById('secondary-navbar');
      const secNavBarHeight = secNavBar?.getBoundingClientRect().height;

      //the 'containsArray' boolean utility for checking whether the menu contains 
      //any submenus. i.e - array within array.

      //scenario - when the L1 menu does not contain L2 menu, in that scenario, 
      //the L1 menu was showing a gap of 2px between menubar and L1 menu, -2 when
      //no submenu 'setSecMenuTopPos(secNavBarHeight-2)'
      const containsArray = subMenuItems?.some(obj => {
        return Object.values(obj).some(value => Array.isArray(value));
      });

      if (containsArray) {
        setSecMenuTopPos(secNavBarHeight)
      } else setSecMenuTopPos(secNavBarHeight-2)
    }
    calcSecMenuTop();
    window.addEventListener('resize', calcSecMenuTop);
    return () => {
      window.removeEventListener('resize', calcSecMenuTop)
    }
  }, [secNav])

  //calculating the Header height and minus from the parentTop
  //value plus minro pixel adjustment helps to calculate the 
  //top position of the L2 menu item.
  const headerEl = document.getElementsByTagName("header")[0];
  const headerElHeight = headerEl?.getBoundingClientRect().height;
  const childMenuTopPos = Math.round(parentTop - headerElHeight);

  const animation = useSpring({
    opacity: dropdown ? 1 : 0,
  });

  return (
    /* On L2 menu, if the menu depth is 2 then changing the top value to -(calculated top),
    border radius for top/left, bottom/left
    */
    <animated.div
      className={`${styles.dropDownContainer} ${dropdown ? styles.show : ""}`}
      style={{
        top: (secNav && depthLevel === 1) ? secMenuTopPos : depthLevel === 2 ? -(childMenuTopPos) : topPos,
        left: leftPos,
        minHeight: height,
        ...borderRds,
        ...animation,
      }}
    >
      <ul className={styles.dropDownList}>
        {depthLevel > 1 && <li className={styles.dropDownName}>{parentMenuName}</li>}
        {subMenuItems?.map((subMenu, index) => {
          return (
            <MenuItems
              {...{ items: subMenu, depthLevel, ...rest }}
              key={index} />
          );
        })}
      </ul>
    </animated.div>
  );
};

export default React.memo(DropDown);
