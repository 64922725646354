import React from "react";

import MenuItems from "../MenuItems";
import styles from "./navbar.module.css";

const NavBar = (props) => {
    const {
        menuItems = [],
        isPrimaryNav = false,
        isLastVisitedFranchiseRibbonActive = false,
        secNav = false,
        handleOverLayEnter = {},
        handleOverLayLeave = {},
        ...rest
    } = props;
    const depthLevel = 0;

    return (
        <nav className={`${styles.navMenuContainer} ${!isPrimaryNav ? styles.secondaryNavBg : ""}`} > 
            <ul className={`${styles.navMenuItems} ${isPrimaryNav && isLastVisitedFranchiseRibbonActive ? 'me-4' : ""}`}>
                {menuItems && menuItems?.map((menu, index) => {
                    return (
                        <div onMouseEnter={menu.headerSubMenu && menu.headerSubMenu.length > 0 ? handleOverLayEnter : undefined }
                             onMouseLeave={menu.headerSubMenu && menu.headerSubMenu.length > 0 ? handleOverLayLeave : undefined }
                             onClick={menu.headerSubMenu && menu.headerSubMenu.length > 0 ? handleOverLayLeave : undefined } >
                            <MenuItems
                                {...{ items: menu, depthLevel, secNav, index, ...rest }} // this constains heating cooling, plumbing, our compmany, plumbing 2, plumbing
                                key={index}
                            />
                        </div>
                    );
                })}
            </ul>
        </nav>
    );
};

export default NavBar;
