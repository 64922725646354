import { useLocation } from "react-router-dom";
import { messageForExternalFranchise } from "../constants/externalFranchiseDetails";
import { externalFranchiseDetails } from "../constants/externalFranchiseDetails";

const useDisplayMsgForExternalFranchise = (queryParam) => {
  const franchiseIdQueryParam = new URLSearchParams(useLocation().search);
  const franchiseId = franchiseIdQueryParam.get(queryParam);

  return externalFranchiseText(franchiseId);
};

const externalFranchiseText = (externalFranchiseId) => {
  const franchiseDetail = externalFranchiseDetails.find(
    (franchiseId) => franchiseId.id === externalFranchiseId
  );

  const sourceFranchise = franchiseDetail?.sourceFranchiseName;

  const externalFranchiseData = {
    "message": sourceFranchise === undefined ? "" : messageForExternalFranchise(franchiseDetail?.sourceFranchiseName),
    "blogRedirectDetails": franchiseDetail?.blogRedirectDetails,
    "id": franchiseDetail?.id
  }
  return sourceFranchise === undefined ? "" : externalFranchiseData


};

export default useDisplayMsgForExternalFranchise;
