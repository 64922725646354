import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchLineOfBusiness } from "../../redux-toolkit/slice/lineOfBusiness/lineOfBusinessSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import HeaderContext from "../../context/HeaderCtx";

const LineOfBusiness = () => {
  const { franchiseData } = useContext(HeaderContext)
  const { lobType } = useParams();
  const { data, isError, statusCode } = useSelector(
    (state) => state.lineOfBusiness
  );
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const stateRefs = useRef({});

  useMetaDetails(data);

  /**
   * @function handleOnClickRedirectAction 
   * @param {*} event 
   * @param {*} redirectUrl 
   */
  const handleOnClickRedirectAction = (event, redirectUrl) => {
    event.preventDefault();
    navigate(`${redirectUrl}`);
  };

  useEffect(() => {
    const payLoad = `?lobtype=${lobType}`;
    dispatch(fetchLineOfBusiness(payLoad));
  }, [lobType]);

  /**
   * @function useEffect
   */
  useEffect(() => {
    if (lobType === "plumbing") {
      const gtmScript = document.createElement("script");
      gtmScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-11068479932');
      `;
      document.head.appendChild(gtmScript);

      // Google Ads (gtag.js) script
      const gtagScript = document.createElement("script");
      gtagScript.src =
        "https://www.googletagmanager.com/gtag/js?id=AW-11068479932";
      gtagScript.async = true;
      document.head.appendChild(gtagScript);
    }
  }, [lobType]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            externalFranchiseMessage={externalFranchiseMessage}
            handleOnClickRedirectAction={handleOnClickRedirectAction}
            franchiseData={franchiseData}
            stateRefs={stateRefs}
          />
        );
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default LineOfBusiness;
