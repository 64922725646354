import React, { useState, useEffect } from 'react';
import { useSpring } from "@react-spring/web";

import NavBar from './NavBar';
import HamBurgerMenu from "../SvgIconComponents/HamBurgerMenu/HamBurgerMenu";
import ServiceRequestCTA from './ServiceRequestCTA';
import LastVisitedFranchise from './LastVisitedFracnhise';
import SecondaryNavBar from './SecondayNavBar';
import MobileSlidePanel from './MobileSlidePanel';
import Logo from './MobileSlidePanel/MultiLevelMenu/Logo';
import "../../stylesheets/rds-bootstrap.css";
import styles from "../../stylesheets/rds-header.module.css";

const RdsHeaderTray = (props) => {
  const {
    logo = {},
    rootMenu: menuItems = [],
    footerLinks: headerCommonMenu = [],
    serviceRequestData = {},
    lastVisitedFranchiseData = {},
    recentFranchisePath = "",
    isLastVisitedFranchiseRibbonActive = false,
    isSecondaryNavigationActive = false,
    headerMenu = [],
    franchiseId,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const handleOverLayEnter = () => {
    setOverlayVisible(true);
  };

  const handleOverLayLeave = () => {
    setOverlayVisible(false);
  };

  //Menu Toggle - Ope/Close Handler
  const handleMenuToggle = () => setIsOpen(!isOpen);

  //prevent the body scroll when mobile menu opened
  useEffect(() => {
    if (isOpen && window.innerWidth < 768) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }
    return () => {
      document.body.classList.remove(styles.noScroll);
    }
  }, [isOpen]);

  //overlay fadein effect
  const overLayStyles = useSpring({
    from: {
      display: "none",
      opacity: 0,
    },
    display: "block",
    opacity: 1,
    config: { mass: 1, tension: 35, friction: 10 },
  });

  return (
    <>
      {isOverlayVisible && <div className={styles.menuOverLay} style={{ ...overLayStyles }} />}
      <header className={`fixed-top ${styles.headerContainer}`}>
        <nav className="navbar navbar-light bg-white p-0">
          <div className="container">

            {/* Mobile menu HamBurgerMenu */}
            <div className="col-2 d-block d-sm-block d-md-none d-lg-none">
              <button
                className={`border-0 bg-transparent`}
                onClick={handleMenuToggle}
              >
                <HamBurgerMenu />
              </button>
            </div>

            {/* Logo Panel for Desktop/Mobile */}
            <div className={`col-10 col-md-6 d-flex ${styles.logoPanel}`}>
              <Logo {
                ...{
                  ...logo,
                  imgRatio: {
                    width: "207",
                    height: "65"
                  },
                  franchiseId,
                  imageClass: styles.image
                }
              }
              />
            </div>

            {/* Menus & CTA section */}
            <div className="col-md-6 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
              <div className={styles.menuContainer}>
                <NavBar
                  {
                  ...{
                    menuItems,
                    isPrimaryNav: true,
                    isLastVisitedFranchiseRibbonActive,
                    handleMenuToggle,
                    handleOverLayEnter,
                    handleOverLayLeave,
                  }
                  }
                />
                {isLastVisitedFranchiseRibbonActive &&
                  <ServiceRequestCTA
                    {
                    ...{
                      ...serviceRequestData,
                      recentFranchisePath,
                      handleMenuToggle
                    }
                    }
                  />
                }
              </div>
            </div>
          </div>
        </nav>

        {/* Last Visisted Franchise Details Ribbon */}
        {isLastVisitedFranchiseRibbonActive &&
          <LastVisitedFranchise
            {
            ...{
              ...lastVisitedFranchiseData,
              recentFranchisePath
            }
            }
          />
        }

        {/* Business Unit specific Seconday Navigation MenuBar */}
        {
          isSecondaryNavigationActive && (
            <SecondaryNavBar
              {
              ...{
                menuItems: headerMenu,
                isSecondaryNavigationActive,
                isLastVisitedFranchiseRibbonActive,
                handleMenuToggle,
                handleOverLayEnter,
                handleOverLayLeave
              }
              }
            />
          )
        }
        {/* Mobile Slide Menu */}
        {isOpen &&
          <div className="position-relative">
            <MobileSlidePanel
              {
              ...{
                logo,
                handleMenuToggle,
                headerMenu: isSecondaryNavigationActive ? headerMenu : menuItems,
                headerCommonMenu,
                isLastVisitedFranchiseRibbonActive,
                lastVisitedFranchise: { ...lastVisitedFranchiseData, recentFranchisePath },
                serviceRequestData,
                franchiseId
              }
              } />
          </div>
        }
      </header>
    </>
  )
}

export default RdsHeaderTray;
