export const ErrorConfiguration = {
  headerText500: "Oops! Something Went Wrong",
  detailText500:
    "We're encountering a glitch. Please try refreshing the page or come back later. If the issue persists, reach out to us, and we'll do our best to fix it. ",
  thankText: "Thank you for your understanding!",
  headerText404: "Page Not Found",
  detailText404:
    "The page you’re looking for might have been removed, had its name changed, or temporarily unavailable. Head back to our homepage and start anew. Or, feel free to explore some of our other pages.",
  buttonText: "RETURN TO HOMEPAGE",
};
