import HomeHeroImg from "../assets/home/desktop/AHHS_Hero.png";
import FrontDoor from "../assets/home/desktop/what_is_ace_home_services.png";

const ACEHOMESERVICES = "Ace Hardware Home Services";

export const HomeBannerData = {
  title: ACEHOMESERVICES,
  subTitle: "Bringing Helpful to Your Home ℠",
  description: "",
  linkText: "",
  url: "",
  bannerImage: HomeHeroImg,
  showArrow: false,
  linkColor: "#fff",
};

export const HomeContentData = {
  title: "What is Ace Hardware Home Services?",
  header: ACEHOMESERVICES,
  titleDescription:
    "Ace Hardware Home Services, with the power of the Ace brand, strives to be the best, most trusted provider of home preservation services. A wide range of residential maintenance and home repair services are available, including plumbing, heating, cooling, electrical, handyman and painting.",
  linkText: "Learn More About Us",
  linkUrl: "/about-us",
  showArrow: true,
  linkColor: "#d5202e",
  image: FrontDoor,
  division: "left",
  altImgText: "Ace Hardware Home Services",
  background: "var(--neutral-050, #F7F7F7)",
};
