import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchBlogPost } from "../../redux-toolkit/slice/blogPost/blogPostSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import {
  addSelectedFilter,
  clearAllFilter,
} from "../../redux-toolkit/slice/blogsFilter/blogsFilterSlice";
import FranchiseExternalMessageTray from "../../AceHomeServicesRds/src/components/rds-external-system-message";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";


const BlogPost = () => {
  const { blogPostName } = useParams();
  const { data, isError, statusCode } = useSelector((state) => state.blogPost);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");


  useEffect(() => {
    const payLoad = `?BlogPostName=${blogPostName}`;
    dispatch(fetchBlogPost(payLoad));
  }, [blogPostName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  /**
   * @function handleRedirectFromBLP
   * @param {*} data
   */
  const handleRedirectFromBLP = (data) => {
    const { selectedCategory, redirectURL } = data;
    dispatch(clearAllFilter());
    dispatch(addSelectedFilter(selectedCategory));
    navigate(redirectURL);
  };

  /**
   * @function handleOnClickRedirectAction
   * @param {*} redirectURL
   */
  const handleOnClickRedirectAction = (redirectUrl) => {
    navigate(redirectUrl);
  };
  /**
   * @function handleSeeAllBlogPosts
   * @param {*} redirectURL
   */
  const handleSeeAllBlogPosts = (redirectURL) => {
    navigate(redirectURL);
  };
  return (
    <>
      {
        Boolean(externalFranchiseMessage) && <FranchiseExternalMessageTray externalFranchiseMessage={externalFranchiseMessage} />
      }
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            handleRedirectFromBLP={handleRedirectFromBLP}
            onClickRedirectAction={handleOnClickRedirectAction}
            handleSeeAllBlogPosts={handleSeeAllBlogPosts}
          />
        );
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default BlogPost;
