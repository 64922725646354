import React from 'react';
import { Link } from 'react-router-dom';
import Canella from "../../../../assets/header/Canella.png";
import Detmer from "../../../../assets/header/Detmer.png";
import DickHill from "../../../../assets/header/DickHill.png";
import Minyard from "../../../../assets/header/Minyard.png";
import Rye from "../../../../assets/header/Rye.png";

const Logo = (props) => {

    const { url, alt, path, imgRatio = {}, imageClass = "",  franchiseId} = props;
    const { width, height } = imgRatio;
    
    const headerLogoMap = {
        "1122": Detmer,
        "1133": DickHill,
        "1144": Rye,
        "1155": Canella,
        "1166": Minyard,
        "1177": "DelawareValley",   
        "1188": "Philadelphia",
        "1199": "LehighValley"
      };
    return (
        <Link
            className={`navbar-brand p-0`}
            to={url}
        >
            <img
                src={franchiseId ? headerLogoMap[franchiseId] : path}
                alt={alt}
                className={imageClass}
                width={width}
                height={height}
            />
        </Link>
    )
}

export default Logo
