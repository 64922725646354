export const externalFranchiseDetails = [
  {
    id: "1122",
    franchiseName: "dayton",
    sourceFranchiseName: "Detmer & Sons",
    targetFranchiseName: "Dayton, OH",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1133",
    franchiseName: "richmond",
    sourceFranchiseName: "Dick Hill & Son",
    targetFranchiseName: "New Lebanon, OH",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1144",
    franchiseName: "hickory-and-denver-hvac",
    sourceFranchiseName: "Rye",
    targetFranchiseName: "Hickory & Denver, NC",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1155",
    franchiseName: "hickory-and-denver-hvac",
    sourceFranchiseName: "Canella",
    targetFranchiseName: "Hickory & Denver, NC",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1166",
    franchiseName: "hickory-and-denver",
    sourceFranchiseName: "Minyard",
    targetFranchiseName: "Hickory & Denver, NC",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1177",
    franchiseName: "delaware-valley",
    sourceFranchiseName: " All Phase Electric Co",
    targetFranchiseName: "Delaware Valley, PA",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1188",
    franchiseName: "philadelphia",
    sourceFranchiseName: "Unique Indoor Comfort (Philadelphia)",
    targetFranchiseName: "Philadelphia, PA",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
  {
    id: "1199",
    franchiseName: "lehigh-valley",
    sourceFranchiseName: "Werley Heating & Air Conditioning ",
    targetFranchiseName: "Lehigh Valley, PA",
    blogRedirectDetails: {
      redirectUrl: "/blogs/who-is-ace-hardware-home-services",
      redirectText: "Learn More",
    },
  },
];

export const messageForExternalFranchise = (sourceFranchise) => {
  return `${sourceFranchise} is now Ace Hardware Home Services.  `;
};
