import React from 'react';
import { Link } from 'react-router-dom';
import Phone from "../../SvgIconComponents/Phone";
import Location from "../../SvgIconComponents/Location";
import { formatPhoneNumber } from '../../../utility';
import { ACE_BUSINESS_NAME } from '../../../constant';
import { dataPush } from "../../../GAnalytics";
import { PHONE_NUMBER_CLICK } from "../../../gaTrack";
import styles from "./lastvisitedfranchise.module.css";

export const FormatFranchise = ({ recentFranchisePath, franchiseName, franchiseCity, franchiseStateAbbreviation }) => {
    const name = franchiseName?.split('-');
    const formattedName = <>
        <span className={`${styles.fontInherit} ${styles.displayStyles} ${styles.fw700}`}>{`${name[0].trim()} - ${franchiseCity}, ${franchiseStateAbbreviation}`}</span>
    </>

    return (
        <Link to={`${recentFranchisePath}`} className={styles.formatFranchise}>
            {formattedName}
        </Link>
    )
}

const LastVisitedFranchise = (props) => {
    const {
        recentFranchisePath = "",
        franchiseName = "",
        franchiseCity = "",
        franchiseStateAbbreviation = "",
        franchisePhoneNumber = "",
        franchiseAddress = ""
    } = props;

    const handleWindowTracker = (e) => {
        e.stopPropagation();
        const location = window.location.href;
        // Google Analytics event tracking code
        dataPush(
            PHONE_NUMBER_CLICK,
            franchiseName,
            franchiseAddress,
            location
        );
    };

    return (
        <>
            <div
                className={styles.lvFranchiseRibbonContainer}
                id="franchise-ribbon"
            >
                <div className="container">
                    <div className={styles.lvFranchiseRibbonContent}>
                        <div className={styles.group}>
                            <Location />
                            <FormatFranchise {
                                ...{
                                    franchiseName: franchiseName?.includes('Ace') ? franchiseName : `${ACE_BUSINESS_NAME} - ${franchiseName}`,
                                    franchiseCity,
                                    franchiseStateAbbreviation,
                                    recentFranchisePath
                                }}
                            />
                        </div>
                        {franchisePhoneNumber &&
                            <div className={`${styles.group} ${styles.phoneGroup}`}>
                                <Phone fillColor="#121212"/>
                                <a
                                    href={`tel:${franchisePhoneNumber}`}
                                    className={`text-nowrap ${styles.fontInherit} ${styles.displayStyles} ${styles.fw700}`}
                                    onClick={(e) => handleWindowTracker(e)}
                                >
                                    {formatPhoneNumber(franchisePhoneNumber)}
                                </a>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(LastVisitedFranchise);
